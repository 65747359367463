<template>
  <v-container class="pa-0" fluid>
    <v-img src="../../public/comming_soon/1.jpg" height="100vh">
      <v-overlay class="d-flex text-center" :opacity="0.4">
        <v-container class="mt-n12" style="max-width: 800px">
          <!-- Title -->
          <v-card-text
            class="unselectable white--text thin"
            style="font-size: 3rem; line-height: 3rem"
          >
            Vitajte na stránke
            <span class="bold primary--text"> AmbienTech </span>
          </v-card-text>
          <!-- Text -->
          <v-card-text
            class="unselectable white--text thin"
            style="font-size: 1rem; line-height: 1.5rem"
          >
            Na tejto stránke momentálne usilovne pracujeme. Už čoskoro tu
            nájdete informácie o tom čo sú to odvetrané fasády, aké materiály sú
            na trhu k dispozícii, ako prebieha samotná realizácia a mnoho
            ďalšieho.
          </v-card-text>
          <v-card-text
            class="unselectable white--text thin"
            style="font-size: 1rem; line-height: 1.5rem"
          >
            O spustení stránky Vás budeme informovať prostredníctvom nášho
            instagramového účtu. V prípade že máte nejaké otázky alebo máte
            záujem o naše služby, neváhajte nás kontaktovať.
          </v-card-text>
        </v-container>
      </v-overlay>
    </v-img>
  </v-container>
</template>

<script>
export default {
  name: "CommingSoon",
  computed: {
    size() {
      return this.$vuetify.breakpoint.xsOnly ? "90vw" : "50vw";
    },
  },
};
</script>