import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#2d96b9', //45,150,185
                secondary: '#00d3e4',   
                darken: '#1f6780', //rgba(25, 83, 103, 1)
                background: '#eeeeee',
                footer: '#2e2e2e'
            },
            dark:{
                // primary: '#2c95ba',
                // secondary: '#4bacc6',
                // background: '#2e2e2e',
            },
        }
    },
});
