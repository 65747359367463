<template>
  <v-app>
    <!-- MAIN CONTENT -->
    <v-main>
      <CommingSoon />
    </v-main>

    <!-- FOOTER -->
    <v-footer class="transparent" absolute>
      <v-container class="pa-0" fluid>
        <div class="text-center">
          <v-btn
            class="instagram-btn"
            color="white"
            href="https://www.instagram.com/ambientech.sk/"
            target="_blank"
            rounded
            text
            small
          >
            <v-icon size="25"> mdi-instagram </v-icon>
            <p class="ma-0 ml-2">Instagram</p>
          </v-btn>
        </div>
        <div class="my-4 text-center white--text">
          <v-icon size="25" color="secondary"> mdi-email-outline </v-icon>
          {{ companyEmail }}
          <v-icon class="ml-6" size="25" color="secondary">
            mdi-phone-outline
          </v-icon>
          {{ companyPhone }}
        </div>
        <v-divider class="white"></v-divider>
        <v-row class="ma-0 text-center">
          <v-card-text class="unselectable white--text">
            Copyright © 2022 {{ companyName }}
          </v-card-text>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import CommingSoon from "./components/CommingSoon";

export default {
  name: "App",
  components: { CommingSoon },
  data() {
    return {
      companyName: "BoltLine s.r.o.",
      companyEmail: "info@boltline.sk",
      companyPhone: "+421 917 380 165",
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
#app {
  font-family: "Quicksand", sans-serif;
  background-color: var(--v-background-base);
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.thin {
  font-weight: 300;
}
.bold {
  font-weight: 500;
}
.instagram-btn {
  color: var(--v-footer-base) !important;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
</style>
